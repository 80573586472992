











































import { Vue, Component, Prop } from "vue-property-decorator";
import InputLabel from "./InputLabelWrapper.vue";

@Component({
  components: {
    "input-label": InputLabel
  }
})
export default class SelectInput extends Vue {
  @Prop({ default: null }) defaultValue?: any;
  private select = this.defaultValue ? this.defaultValue : "";
  @Prop() items!: string;
  @Prop() isReadOnly!: boolean;
  @Prop({ default: false }) isRequired!: boolean;
  @Prop() labelText!: string;
  @Prop({ default: "" }) placeholder!: string;
  @Prop({ default: "" }) customClass!: string;
  @Prop({ default: false }) checkboxItems?: boolean;
  @Prop({ default: false }) persistentPlaceholder?: boolean;

  private resetSelected() {
    this.select = [];
    this.change();
  }

  private change() {
    this.$emit("lookup", this.select);
  }
}
