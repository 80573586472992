









































































import { Vue, Component, Prop } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import moment from "moment";
import InvoiceListStore from "@/store/invoice-list.store";
import InvoicesTable from "@/components/invoices-list/InvoicesTable.vue";
import { serviceManager } from "@/services/invoices-list/service-manager.service";
import { RavenApiMetadata } from "@/models/external-services/raven-api-standard-response";
import {
  InvoiceListTableData,
  SortOptions,
  TableTab
} from "@/models/invoices-list/invoice-list-table-data";
import { InvoicesType, RefID } from "@/shared/constants/invoices-endpoints";
import TableSkeleton from "@/components/skeletons/TableSkeleton.vue";

@Component({
  components: {
    "invoices-table": InvoicesTable,
    "table-skeleton": TableSkeleton
  }
})
export default class InvoicesTableTemplate extends Vue {
  // readonly store module
  private readonly invoiceListStore = getModule(InvoiceListStore, this.$store);

  // props from parent component
  @Prop() invoicesGroup!: string;
  @Prop({ default: true }) actionsTable?: boolean;
  @Prop() invoicesListScreen!: string;

  // reactive class properties
  private data: InvoiceListTableData | undefined;
  private metadata:
    | {
        page: number;
        pageResults: number;
        pageSize: number;
        totalPages: number;
        totalResults: number;
        itemsPerPage: number;
        currentPage: number;
        totalItems: number;
        lastPage: number;
      }
    | undefined;
  private isFetching = true;
  private fetching = true;
  private filterableStates: string[] = [];
  private tabAssignments: string[] = [];
  private invoiceGroupRoute!: string;
  private storedInvoicesOrder = this.invoiceListStore.getStoredTableOrderObject;
  private documentsRoute = "/documents";
  private completedDocumentsRoute = "/complete-documents";
  private validationServicesRoute = "/validation-services";

  private storedInvoicesListPath = this.invoiceListStore
    .getStoredInvoicesListPathObject;

  //remember the current endpoint
  private loadEndpointToStore(tab: string) {
    const wasListScreenChanged =
      this.invoicesListScreen !=
      this.storedInvoicesListPath.invoicesListScreenString;
    const wasInvoicesGroupChanged =
      this.invoicesGroup != this.storedInvoicesListPath.invoicesGroupString;
    if (wasListScreenChanged || wasInvoicesGroupChanged) {
      const changeSortOrder = this.changeSortOrder();
      this.resetAllTableFilters(changeSortOrder);
    }
    this.invoiceListStore.updateInvoicesListPathObject({
      invoicesListScreenString: this.invoicesListScreen,
      invoicesGroupString: this.invoicesGroup,
      invoicesStatusString: tab
    });
  }

  private changeSortOrder(): boolean {
    if (this.invoiceGroupRoute !== this.$route.path) {
      const storedObject = this.invoiceListStore.getStoredOptionsObject;
      let keyName = "";
      switch (this.invoicesListScreen) {
        case "DocumentList": {
          if (
            this.storedInvoicesListPath.invoicesListScreenString ==
            "DocumentList"
          ) {
            keyName =
              this.storedInvoicesListPath.invoicesGroupString == "completed"
                ? this.completedDocumentsRoute
                : this.documentsRoute;
          } else if (
            this.storedInvoicesListPath.invoicesListScreenString ==
            "ValidationServicesList"
          ) {
            keyName = this.validationServicesRoute;
          }
          break;
        }
        case "ValidationServicesList": {
          if (
            this.storedInvoicesListPath.invoicesListScreenString ==
            "DocumentList"
          ) {
            keyName =
              this.storedInvoicesListPath.invoicesGroupString == "completed"
                ? this.completedDocumentsRoute
                : this.documentsRoute;
          }
          break;
        }
        default: {
          break;
        }
      }
      const tableSortOrder = {
        key: keyName,
        value: storedObject
      };
      this.invoiceListStore.updateTableOrderObject(tableSortOrder);
      this.invoiceGroupRoute = this.$route.path;
      return true;
    }
    return false;
  }

  //This method resets most of the invoices table filters when new tab is selected
  //avoiding apply filters that do not belongs to the invoices of the new tab.
  private resetAllTableFilters(changeSortOrder?: boolean) {
    const storedObject = this.invoiceListStore.getStoredOptionsObject;
    const storedSortedObject = this.invoiceListStore.getStoredTableOrderObject;
    const valueSortEmpty: SortOptions = { sortBy: [], sortDesc: [] };
    const path = this.$route.path;
    const isVsRoute = path.includes("validation");
    const keyToSearch = isVsRoute
      ? this.validationServicesRoute
      : this.invoiceGroupRoute;
    this.tabAssignments = [];
    this.filterableStates = [];
    this.invoiceListStore.updateFilterObject([]);
    this.invoiceListStore.updateSearchString("");
    this.invoiceListStore.updateSelectedStateString("");

    if (changeSortOrder) {
      let isExistingOrder = false;
      isExistingOrder = keyToSearch in storedSortedObject;
      this.invoiceListStore.updateOptionsObject({
        sortBy: isExistingOrder
          ? this.storedInvoicesOrder[keyToSearch].sortBy
          : [],
        sortDesc: isExistingOrder
          ? this.storedInvoicesOrder[keyToSearch].sortDesc
          : [],
        groupBy: [],
        groupDesc: [],
        page: 1,
        itemsPerPage: storedObject.itemsPerPage,
        multiSort: true,
        mustSort: false
      });
      if (!isExistingOrder)
        this.invoiceListStore.updateTableOrderObject({
          key: isVsRoute ? this.validationServicesRoute : path,
          value: valueSortEmpty
        });
    }
  }

  // computed properties
  // menu filters section
  private get filters() {
    return this.invoiceListStore.getStoredFilterObject;
  }
  private set filters(value: any) {
    this.invoiceListStore.updateFilterObject(value);
  }
  // invoices tab section
  private get selectedUiTab() {
    if (this.$route.query.tab) {
      return this.$route.query.tab as string;
    } else {
      let tab = "";
      const storedInvoicesType = this.storedInvoicesListPath
        .invoicesGroupString;
      const storedRefID = this.storedInvoicesListPath.invoicesStatusString;
      if (
        storedInvoicesType == "" ||
        (storedInvoicesType != "" && this.invoicesGroup != storedInvoicesType)
      ) {
        tab =
          this.invoicesGroup === InvoicesType.ACTIVE
            ? RefID.ACTIVE.ALL
            : RefID.COMPLETED.SUBMITTED;
      }
      if (
        storedInvoicesType != "" &&
        this.invoicesGroup == storedInvoicesType
      ) {
        tab = storedRefID;
      }
      return tab;
    }
  }

  private set selectedUiTab(tab: string) {
    // for completed invoices, set date range to single business week to avoid querying an excessive number of documents
    if (tab === "submitted" || tab === "cancelled") {
      this.invoiceListStore.updateDateRange(
        this.invoiceListStore.defaultDateRange
      );
    }
    this.loadEndpointToStore(tab);
    this.resetAllTableFilters();
    this.$router.push({ query: { tab } }).catch(err => {});
  }

  // tabAssignments section
  private get selectedAssignments(): string[] {
    if (!this.$route.query.assignment) {
      return [];
    } else {
      return typeof this.$route.query.assignment == "string"
        ? [this.$route.query.assignment]
        : (this.$route.query.assignment as string[]);
    }
  }

  private set selectedAssignments(assignments: string[]) {
    this.$router
      .push({
        query: {
          tab: this.selectedUiTab,
          assignment: assignments
        }
      })
      .catch((error: any) => console.log(error));
    this.filters = { ...this.filters, assignment: assignments };
    if (assignments.length === 0) {
      delete this.filters.assignment;
    }
  }

  private get dateRange() {
    return this.invoiceListStore.getDateRange;
  }

  private get limitedToBusinessWeek(): boolean {
    return (
      this.invoicesGroup === "completed" &&
      this.dateRange.startDate ===
        this.invoiceListStore.defaultDateRange.startDate &&
      this.dateRange.endDate === this.invoiceListStore.defaultDateRange.endDate
    );
  }

  private resetCompletedInvoices() {
    this.invoiceListStore.updateDateRange(
      this.invoiceListStore.defaultDateRange
    );
    this.getCompletedInvoices({
      startDate: this.invoiceListStore.defaultDateRange.startDate,
      endDate: this.invoiceListStore.defaultDateRange.endDate
    });
  }

  // methods
  escapeRefID(invoiceTab: TableTab): string {
    const decoder = document.createElement("div");
    decoder.innerText = "#" + invoiceTab.refID.replaceAll("[^a-zA-Z]+", "");
    return decoder.innerText;
  }

  private async fetchData(invoicesStatus: string) {
    this.isFetching = true;
    const apiResponse = await serviceManager(
      this.invoicesListScreen
    ).getInvoiceList(this.invoicesGroup, invoicesStatus);
    // use Object.assign() to maintain reactivity of data object
    if (apiResponse?.data) {
      this.metadata = this.setMetadataObject(apiResponse.data.metadata);
      this.processResponse(apiResponse.data.data);
    }
    this.isFetching = false;
  }

  setMetadataObject(metadataObject: RavenApiMetadata) {
    return {
      ...metadataObject,
      itemsPerPage: 20,
      currentPage: 1,
      totalItems: metadataObject.pageResults,
      lastPage: Math.ceil(metadataObject.pageResults / 20)
    };
  }

  processResponse(apiData: InvoiceListTableData) {
    const statuses: string[] = [];
    const assignments = new Set();
    apiData.tableItems.forEach((item: any) => {
      if (item.invoiceDate) {
        item.invoiceDate = moment(item.invoiceDate).format("MM/DD/YYYY");
      }
      if (item.dueDate) {
        item.dueDate = moment(item.dueDate).format("MM/DD/YYYY");
      }
      statuses.push(...item.status);
      assignments.add(item.assignment);
    });
    this.filterableStates = Array.from(new Set(statuses));
    this.tabAssignments = (Array.from(assignments) as string[])
      .filter((tab: string) => tab?.length > 0)
      .sort();
    this.data = apiData;
  }

  handleAssignmentClick(clickedAssignment: string[]): void {
    this.selectedAssignments = clickedAssignment;
  }

  async getCompletedInvoices(selection: {
    startDate: string;
    endDate: string;
  }) {
    this.invoiceListStore.updateDateRange(selection);
    const apiResponse = await serviceManager(
      this.invoicesListScreen
    ).getInvoiceList(
      this.invoicesGroup,
      this.selectedUiTab,
      this.dateRange.startDate,
      this.dateRange.endDate
    );
    this.metadata = this.setMetadataObject(apiResponse.data.metadata);
    this.processResponse(apiResponse.data.data);
    this.isFetching = false;
  }

  // component lifecycle methods
  async created() {
    this.loadEndpointToStore(this.selectedUiTab);
    this.invoiceGroupRoute = this.$route.path;
    if (this.invoicesGroup === "active") {
      this.fetchData(this.selectedUiTab);
    } else {
      this.getCompletedInvoices({
        startDate: this.invoiceListStore.defaultDateRange.startDate,
        endDate: this.invoiceListStore.defaultDateRange.endDate
      });
    }
    this.invoiceListStore.updateTabFilterObject(this.selectedUiTab);
    // below logic sets filters when user navigates from Dashboard or another page
    // to a specific document group
    if (!this.$route.query.tab && this.filters.assignment) {
      this.selectedAssignments = this.filters.assignment;
    }
    //reset table filters when navigating to a tab from query
    if (this.$route.query.tab && !this.$route.query.assignment) {
      this.resetAllTableFilters();
    }
    if (this.selectedAssignments?.length > 0) {
      this.filters = {
        ...this.filters,
        assignment: this.selectedAssignments
      };
    }
  }
}
