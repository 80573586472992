import invoiceList from "@/shared/constants/invoices-list";
import { documentListService } from "./document-list.service";
import { validationServicesService } from "./validation-services.service";

export function serviceManager(service: string) {
  let res: any;
  switch (service) {
    case invoiceList.documentList:
      res = documentListService;
      break;
    case invoiceList.validationServicesList:
      res = validationServicesService;
      break;
    default:
      res = documentListService;
  }
  return res;
}
