






























































































import { Vue, Component, Watch, Prop } from "vue-property-decorator";
import moment from "moment";
import { getModule } from "vuex-module-decorators";
import UserStore from "@/store/user.store";

@Component
export default class DateRangePickerInput extends Vue {
  @Prop() wipe;
  @Prop() isDateInFuture!: boolean;
  private dates: any = null;
  private menu = false;
  private fixedRange = [false, false, false];
  private readonly userStore: UserStore = getModule(UserStore, this.$store);
  private isoDateFormat = "YYYY-MM-DD";

  private async setRange(
    range: number,
    finalRangeDate?: number,
    rangeOption?: number
  ): Promise<void> {
    if (this.fixedRange[range]) {
      this.resetRange();
      return;
    }
    this.resetRange();
    this.$emit("disableWipe", false);
    let optionSelected = range;
    if (rangeOption) {
      optionSelected = rangeOption;
    }
    this.fixedRange[optionSelected] = true;

    if (!this.isDateInFuture) {
      const today = moment()
        .format()
        .toString()
        .substring(0, 10);
      const pastMonth = moment()
        .subtract(range + 1, "months")
        .format()
        .toString()
        .substring(0, 10);
      const newArrDate = [pastMonth, today];
      this.dates = newArrDate;
    } else {
      const initialDay = moment()
        .add(range, "days")
        .format()
        .toString()
        .substring(0, 10);
      const nextDays = moment()
        .add(finalRangeDate, "days")
        .format()
        .toString()
        .substring(0, 10);
      const newArrDate = [initialDay, nextDays];
      this.dates = newArrDate;
    }
  }

  private resetRange() {
    this.dates = null;
    this.fixedRange = this.fixedRange.map(() => false);
  }

  private formatDate(date) {
    if (!date) return null;
    return date;
    // NOTE - regional settings stored in our DB do not match moment.js expectations
    // we will need to update regional settings in order to display different formats

    // return moment(date, this.isoDateFormat).format(
    //   this.userStore.getRegionalSettings
    // );
  }

  private get dateRangeText() {
    return this.dates
      ? [this.formatDate(this.dates[0]), this.formatDate(this.dates[1])].join(
          " - "
        )
      : null;
  }

  @Watch("dates")
  private async emitDates() {
    if (this.dates && this.dates[0] && this.dates[1]) {
      if (this.dates[0] > this.dates[1]) {
        this.dates = [this.dates[1], this.dates[0]];
      }
      this.$emit("lookup", this.dates);
    } else {
      this.$emit("lookup", []);
    }
  }

  @Watch("wipe")
  private wipeAllData() {
    if (this.wipe) {
      this.dates = undefined;
      this.fixedRange = [false, false, false];
    } else {
      this.$emit("disableWipe");
    }
  }
}
