//invoices endpoint structure /api/invoices/{InvoicesType}/{RefID}

export const InvoicesType = {
  ACTIVE: "active",
  COMPLETTED: "completed"
};

export const RefID = {
  ACTIVE: {
    ALL: "all"
  },
  COMPLETED: {
    SUBMITTED: "submitted",
    CANCELLED: "cancelled"
  }
};
