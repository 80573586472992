export const documentStatusTags: {
  text: string;
  code: string;
  color: string;
}[] = [
  {
    text: "In Use",
    code: "IN_USE",
    color: "#3cc13b"
  },
  {
    text: "Past Due",
    code: "PAST_DUE",
    color: "#d26856"
  },
  {
    text: "Duplicate",
    code: "DUPLICATE",
    color: "#d26856"
  },
  {
    text: "Almost Due",
    code: "ALMOST_DUE",
    color: "#d26856"
  },
  {
    text: "On Hold",
    code: "ON_HOLD",
    color: "#cdaf4d"
  },
  {
    text: "New Supplier",
    code: "NEW_SUPPLIER",
    color: "#3e66fb"
  },
  {
    text: "Submitted",
    code: "SUBMITTED",
    color: "#3cc13b"
  },
  {
    text: "Cancelled",
    code: "CANCELLED",
    color: "#cdaf4d"
  }
];
