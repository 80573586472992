


























































































































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import DateRangePickerInput from "@/components/design-system/inputs/DateRangePickerInput.vue";
import StatusTag from "@/components/design-system/tags/StatusTag.vue";
import { cloneDeep } from "lodash";
import moment from "moment";

interface FilterInterface {
  [key: string]: any;
}

@Component({
  components: {
    "date-range-picker-input": DateRangePickerInput,
    "status-tag": StatusTag
  }
})
export default class FilterMenu extends Vue {
  @Prop() filters!: any;
  @Prop() invoiceStatus!: string;
  private filterIconStyle = "filters-disabled";
  private data = cloneDeep(this.filters);
  private isOpen = false;
  private showClearFilters = false;
  private wipeChildsData = false;
  private filtersStore: FilterInterface = this.selectedFilterItems();
  private toggleExpansion = this.data.map(el => false);

  private setClassFocusIn() {
    this.filterIconStyle = "filters-focused";
  }

  updateFilterDate(filterKey: string, value: any) {
    this.filtersStore[filterKey] = value;
  }

  // Data object nedded to render UI data. The data is filtered based on query strings saved on queryObject
  private filteredItems(
    filterKey: string | null = null,
    query: string | null = null
  ): any {
    this.data = cloneDeep(this.filters);
    if (query != "" && query != null) {
      const filteredObject: any[] = this.data.filter(
        (filter: any) => filter.key == filterKey
      );
      if (filteredObject.length) {
        filteredObject[0].items = filteredObject[0].items.filter(
          (item: { name: string }) =>
            item.name.toLowerCase().includes(query.toLowerCase())
        );
        return this.data.map(
          (obj: any) =>
            filteredObject.find((o: any) => o.key === obj.key) || obj
        );
      }
    }
    return this.data;
  }

  private disableWipeValue(value: boolean) {
    this.wipeChildsData = value;
  }

  private resetSettedFilters() {
    this.$emit("resetCompletedInvoices");
    for (const key in this.filtersStore) {
      this.filtersStore[key] = [];
    }
    this.showClearFilters = false;
    this.wipeChildsData = true;
    this.filterIconStyle = "filters-disabled";
  }

  // Object where the selected filter are saved
  private selectedFilterItems() {
    const filterObject: FilterInterface = {};
    for (let i = 0; i < this.filters?.length; i++) {
      filterObject[`${this.filters[i].key}`] = [];
    }
    return filterObject;
  }

  // Object where queries are saved
  private get queryObject() {
    const queryObject: FilterInterface = {};
    for (let i = 0; i < this.filters.length; i++) {
      queryObject[`${this.filters[i].key}`] = "";
    }
    return queryObject;
  }

  private removeFilterKeysWithNotValues(filters): void {
    for (const key in filters) {
      if (filters[key].length) {
        filters[key] = filters[key].filter((x: any) => x !== undefined && x);
        if (!filters[key].length) delete filters[key];
      } else {
        delete filters[key];
      }
    }
  }

  private getCompletedInvoices(value: string[]) {
    if (value[0] && value[1]) {
      const dateRange = {
        startDate: moment(value[0])
          .utc()
          .format("YYYY-MM-DD"),
        endDate: moment(value[1])
          .utc()
          .format("YYYY-MM-DD")
      };
      this.showClearFilters = true;
      this.$emit("getCompletedInvoices", dateRange);
    } else {
      this.$emit("resetCompletedInvoices");
    }
  }

  // Check if filters are set
  private areFiltersSet(filters): boolean {
    return Object.keys(filters).length ? true : false;
  }

  @Watch("isOpen")
  private changeFilterStyles() {
    if (!this.isOpen) {
      const newFilters = cloneDeep(this.filtersStore);
      this.removeFilterKeysWithNotValues(newFilters);
      this.filterIconStyle = this.areFiltersSet(newFilters)
        ? "filters-enabled"
        : "filters-disabled";
      this.toggleExpansion = this.toggleExpansion.map(e => false);
    }
  }

  @Watch("filtersStore", { deep: true })
  private pitUpFilter() {
    const newFilters = cloneDeep(this.filtersStore);
    this.removeFilterKeysWithNotValues(newFilters);
    this.showClearFilters = this.areFiltersSet(newFilters);
    this.$emit("filterChange", newFilters);
  }
}
