








import { Vue, Component } from "vue-property-decorator";
import InvoicesTableTemplate from "@/components/invoices-list/InvoicesTableTemplate.vue";
import invoicesList from "@/shared/constants/invoices-list";

@Component({
  components: {
    "invoices-table-template": InvoicesTableTemplate
  }
})
export default class ValidationServices extends Vue {
  private listScreen = invoicesList.validationServicesList;
}
