





















































































































































































import { Component, Prop, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { cloneDeep } from "lodash";
import IconButton from "@/components/design-system/buttons/IconButton.vue";
import StatusTooltip from "@/components/design-system/tags/StatusTooltip.vue";
import IconTooltip from "@/components/design-system/icons/IconTooltip.vue";
import FilterMenu from "@/components/invoices-list/FilterMenu.vue";
import SimpleConfirmationModal from "@/components/design-system/modals/SimpleConfirmationModal.vue";
import SelectInput from "@/components/design-system/inputs/SelectInput.vue";
import { invoiceService } from "@/services/invoice.service";
import actionsConsts from "@/shared/constants/invoice-actions";
import Catch from "@/shared/decorators/catch-errors";
import { stringToIsoDate } from "@/helpers/string-formatting";
import { utils } from "@/utils/okta-utils";
import InvoiceListStore from "@/store/invoice-list.store";
import { MenuFilter } from "@/models/invoices-list/invoice-list-table-data";
import UserRolesMixin from "@/mixins/UserRoles.vue";

@Component({
  components: {
    "icon-button": IconButton,
    "status-tooltip": StatusTooltip,
    "filter-menu": FilterMenu,
    "icon-tooltip": IconTooltip,
    "simple-confirmation-modal": SimpleConfirmationModal,
    "select-input": SelectInput
  }
})
export default class InvoicesTable extends UserRolesMixin {
  // props from parent component
  @Prop() tableColumnHeaders!: object[];
  @Prop() tableRowItems!: object[];
  @Prop() tabAssignments!: string[];
  @Prop() tableTitle!: string;
  @Prop() invoiceFilterableStates!: string[];
  @Prop() tableFilterMenu!: MenuFilter[];
  @Prop() holdReasons!: string[];
  @Prop() metadata!: object;
  @Prop() limitedToBusinessWeek!: boolean;
  @Prop({ default: true }) assignmentsEnabled?: boolean;
  @Prop({ default: true }) actionsEnabled?: boolean;
  @Prop({ default: true }) checkboxesEnabled?: boolean;
  @Prop({ default: null }) selectedAssignments?: string[] | null;
  // readonly store module
  private readonly invoiceListStore = getModule(InvoiceListStore, this.$store);

  // filters, sorts and search query
  private readonly storedOptionsObject = this.invoiceListStore
    .getStoredOptionsObject;
  private searchQuery = this.invoiceListStore.getStoredSearchString;
  private selectedStatus = this.invoiceListStore.getStoredSelectedState;

  // reactive class properties
  private crudControl = false;
  private selected: any[] = [];
  private showModal = false;
  private modalText = "";
  private modalBodyText = "";
  private modalBodyList: string | string[] = "";
  private modalConfirmationMessage = "";
  private modalHoldReasons: { value: string; options: string[] } | null = null;
  private disablePagination = false;
  private isActionInProgress = false;

  //api parameters
  private selectedAction = "";
  private actionsConstsInternal = actionsConsts;

  // computed properties
  private get filters() {
    return this.invoiceListStore.getStoredFilterObject;
  }
  private set filters(value: any) {
    this.invoiceListStore.updateFilterObject(value);
  }
  private get rowItemsToDisplay() {
    let items = cloneDeep(this.tableRowItems);
    // filter by selected filters/assignments
    if (Object.keys(this.filters).length) {
      for (const key in this.filters) {
        items = items.filter((rowItem: any) => {
          if (key.toLowerCase().includes("date") && rowItem[key]) {
            const itemDate = stringToIsoDate(rowItem[key]);

            return (
              itemDate >= this.filters[key][0] &&
              itemDate <= this.filters[key][1]
            );
          } else {
            return this.filters[key].includes(rowItem[key]);
          }
        });
      }
    }
    // filter by selected state
    if (this.selectedStatus) {
      items = items.filter((rowItem: any) =>
        rowItem["status"].includes(this.selectedStatus)
      );
    }
    return items;
  }
  private set selectedInvoices(selected: any[]) {
    this.selected = cloneDeep(selected);
    this.crudControl = selected.length > 0 ? true : false;
  }
  private get selectedInvoices() {
    return this.selected;
  }
  private get selectedCustomerName() {
    return this.userStore.getSelectedCustomer.description;
  }
  private get isConfirmHoldButtonDisabled() {
    return (
      this.selectedAction === this.actionsConstsInternal.HOLD &&
      this.modalHoldReasons?.value === ""
    );
  }

  private get documentGroupsList() {
    const docGroupList = this.tabAssignments?.map((group: string) => {
      return {
        value: group,
        text: group
      };
    });
    return docGroupList;
  }

  private get documentGroupsPlaceholder() {
    const totalSelected = this.selectedAssignments?.length ?? 0;
    if (totalSelected > 1) {
      return `${totalSelected} Groups Selected`;
    }
    if (totalSelected == 1) {
      return `${totalSelected} Group Selected`;
    }
    return "Document Groups";
  }
  //Button activation on bulk selection
  //Action buttons are only shown if all selected invoices include permission for that action
  private get isHoldButtonShown() {
    let shown = true;
    this.selected.forEach(el => {
      shown = shown && el.permissions.canBePutOnHold;
    });
    return shown;
  }
  private get isResumeButtonShown() {
    let shown = true;
    this.selected.forEach(el => {
      shown = shown && el.permissions.canBeResumed;
    });
    return shown;
  }
  private get isDeleteButtonShown() {
    let shown = true;
    this.selected.forEach(el => {
      shown = shown && el.permissions.canBeDeleted;
    });
    return shown;
  }
  private get isCancelButtonShown() {
    let shown = true;
    this.selected.forEach(el => {
      shown = shown && el.permissions.canBeCancelled;
    });
    return shown;
  }

  private get selectedStatusObject() {
    return this.filterableStatus
      ? this.filterableStatus.filter(
          (el: any) => el.name == this.selectedStatus
        )
      : null;
  }

  // methods
  private setLocalScannedDate(item) {
    return utils.utcToLocalDateString(item.scannedDate);
  }
  private handleModal(action: string) {
    this.modalBodyList = action ? this.getSelectedInvoices(this.selected) : "";
    const invoicesSelectedLength = this.selected.length;
    const areMoreThanOneInvoiceSelected = invoicesSelectedLength > 1;
    this.modalBodyText = areMoreThanOneInvoiceSelected
      ? `these ${invoicesSelectedLength} invoices`
      : "this invoice";
    this.modalConfirmationMessage = areMoreThanOneInvoiceSelected
      ? `Invoices`
      : "Invoice";
    this.selectedAction = action;
    this.modalHoldReasons = null;
    switch (action) {
      case this.actionsConstsInternal.DELETE:
        this.modalText = "Delete";
        break;
      case this.actionsConstsInternal.RESUME:
        this.modalText = "Resume";
        break;
      case this.actionsConstsInternal.HOLD:
        this.modalText = "Hold";
        this.modalHoldReasons = { value: "", options: this.holdReasons };
        break;
      case this.actionsConstsInternal.CANCEL:
        this.modalText = "Cancel";
        break;
      default:
        this.modalText = "";
    }
    this.showModal = !this.showModal;
  }

  private get filterableStatus() {
    return this.invoiceFilterableStates
      ? [
          { code: null, name: "Status" },
          ...this.invoiceFilterableStates.map((el: any) => {
            return { code: el, name: el.replace("_", " ").toLowerCase() };
          })
        ]
      : null;
  }

  private getSelectedInvoices(invoices: any[]): string[] {
    return invoices.map((el: any) =>
      (
        (el.companyName ? el.companyName + ", " : "") +
        (el.supplierName ? el.supplierName + ", " : "") +
        (el.invoiceNumber ? el.invoiceNumber + ", " : "") +
        (el.controlAmount ? el.controlAmount + ", " : "") +
        (el.invoiceDate ? el.invoiceDate + ", " : "")
      ).slice(0, -2)
    );
  }

  private handleAssignmentClick(assignmentString: string[]): void {
    this.$emit("assigmentClicked", assignmentString);
  }

  private handleFilterChange(value: any) {
    this.filters = this.selectedAssignments?.length
      ? { ...value, assignment: this.selectedAssignments }
      : { ...value };
  }

  private handleTableOptionsUpdate(options) {
    this.invoiceListStore.updateOptionsObject(options);
  }

  private loadInvoiceCandidates(_allVisibleItemsInTable: any) {
    const el = this.$children[0].$children[0];
    const items = cloneDeep(el["_computedWatchers"].filteredItems.value);
    const options = cloneDeep(this.storedOptionsObject);
    /* @ts-expect-error */
    const filteredItems = el.sortItems(items, options.sortBy, options.sortDesc);
    this.invoiceListStore.updateInvIDsCandidatesArray(
      filteredItems.map(inv => inv.invID)
    );
  }

  private getCompletedInvoices(selection: {
    startDate: string;
    endDate: string;
  }) {
    this.$emit("getCompletedInvoices", selection);
  }

  private resetCompletedInvoices() {
    this.$emit("resetCompletedInvoices");
  }

  @Catch()
  private async executeAction() {
    this.isActionInProgress = true;
    const selectedIDs = this.selected.map(el => el.invID);
    await invoiceService.updateDocumentStatus(
      this.selectedAction.toLowerCase(),
      selectedIDs,
      this.modalHoldReasons?.value || null
    );
    this.isActionInProgress = false;
    this.$router.go(0);
  }

  // store searchQuery string in vuex each time it changes
  @Watch("searchQuery")
  updateStoredSearchString(newValue: string) {
    this.invoiceListStore.updateSearchString(newValue);
  }

  @Watch("selectedStatus")
  updateStoredSelectedStateString(newValue: string) {
    this.invoiceListStore.updateSelectedStateString(newValue);
  }

  @Watch("storedOptionsObject", { deep: true })
  updateStoredOptionsObject() {
    this.invoiceListStore.updateOptionsObject(this.storedOptionsObject);
  }
}
