














import { Vue, Component, Prop } from "vue-property-decorator";
import { documentStatusTags } from "@/shared/constants/status-tags";

interface StatusTagInterface {
  code: string;
}

@Component
export default class StatusTag extends Vue {
  @Prop() code!: string;
  @Prop({ default: 0 }) numberOfStatuses!: number;
  @Prop() text?: string;
  private statusTagGenerator;

  // Status dynamic tags templates
  private statusTagsTemplates = documentStatusTags;

  // Lifecycle methods
  created() {
    const result = this.statusTagsTemplates.filter(
      (el: StatusTagInterface) => el.code == this.code
    );
    this.statusTagGenerator = result.length
      ? [...result]
      : [{ text: this.text, code: this.code, color: "#d26856" }];
  }
}
