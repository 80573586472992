export function stringIsEmpty(value: string): boolean {
  return value?.trim() == "" ? true : false;
}

export function nextCharacterAt(charcater: string, index: any) {
  return String.fromCharCode(charcater.charCodeAt(0) + index ? index : 1);
}

export function stringToIsoDate(date: string) {
  const auxDate = new Date(date);
  return new Date(auxDate.getTime() - auxDate.getTimezoneOffset() * 60000)
    .toISOString()
    .split("T")[0]
    .toString();
}

/**
 * Formats decimalAsString to a reasonable decimal representation
 * @param decimalAsString string representation of a decimal number
 * @param maxNumOfDecimalPlacesAllowed max number of allowed decimal places. Default value 2
 * @returns string representation of a decimal number whit at
 * least two decimal places and at most maxNumOfDecimalPlacesAllowed
 * decimal places
 */
export function addDecimalsToString(
  decimalAsString: string,
  maxNumOfDecimalPlacesAllowed = 2
): string {
  // If invalid input then returns 0.00
  const isInvalidStringNumberRepresentation =
    decimalAsString == "" ||
    decimalAsString == null ||
    decimalAsString == undefined;
  if (isInvalidStringNumberRepresentation) {
    return "0.00";
  }

  let input = decimalAsString;

  if (typeof decimalAsString == "number") {
    input = String(decimalAsString);
  }

  let splittedInput = input.split(".");

  // If input does not contains decimals then add two trailing
  // zero decimals to the input and return it
  let notContainsDecimals = splittedInput.length == 1;
  if (notContainsDecimals) {
    return splittedInput[0] + ".00";
  }

  const numberOfDecimals = splittedInput[1].length;

  // Returns the input with one aditional zero decimal if the input
  // only contains one decimal
  const containsOneDecimal = numberOfDecimals == 1;
  if (containsOneDecimal) {
    return input + "0";
  }

  // Returns the same input if the input contains the appropiate
  // number of decimals
  const containsTheAppropiateNumOfDecimals = numberOfDecimals == 2;
  if (containsTheAppropiateNumOfDecimals) {
    return input;
  }

  // Round input before it is truncated
  const operand = 10 ** maxNumOfDecimalPlacesAllowed;
  input = (
    Math.round((parseFloat(input.toString()) + Number.EPSILON) * operand) /
    operand
  ).toString();

  splittedInput = input.split(".");

  // If input does not contains decimals then add two trailing
  // zero decimals to the input and return it
  notContainsDecimals = splittedInput.length == 1;
  if (notContainsDecimals) {
    return splittedInput[0] + ".00";
  }

  // Truncates the remaining decimals not contained in the
  // maxNumOfDecimalPlacesAllowed boundary
  if (numberOfDecimals > maxNumOfDecimalPlacesAllowed) {
    splittedInput[1] = splittedInput[1].slice(0, maxNumOfDecimalPlacesAllowed);
  }

  // Removes trailing zeros contained in the maxNumOfDecimalPlacesAllowed
  // boundary until a decimal number other than zero is detected or the
  // amount of two decimals is reached
  if (splittedInput[1].length == 1) {
    splittedInput[1] += "0";
  } else {
    let lastDecimalIndex = splittedInput[1].length - 1;
    let twoDecimalPlacesReached = lastDecimalIndex == 1;
    let currentDecimalIsZero = splittedInput[1][lastDecimalIndex] == "0";
    while (currentDecimalIsZero && !twoDecimalPlacesReached) {
      splittedInput[1] = splittedInput[1].slice(0, lastDecimalIndex);
      lastDecimalIndex--;
      twoDecimalPlacesReached = lastDecimalIndex > 1;
      currentDecimalIsZero = splittedInput[1][lastDecimalIndex] == "0";
    }
  }

  return splittedInput.join(".");
}
