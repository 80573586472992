import { app } from "@/main";
import { DocumentOrderRequest } from "@/models/external-services/document-order-request-body";

interface UpdatedInvoiceResponse {
  success: boolean;
  description: string;
  code: number;
  invId: number;
}

class InvoiceService {
  private processDocumentStatusUpdateResponse(
    apiDataResponse: UpdatedInvoiceResponse[]
  ): string {
    const description = apiDataResponse.reduce((description, item) => {
      if (!item.success) {
        description += "<br>" + item.description;
      }
      return description;
    }, "");
    if (description != "") throw new Error(description);
    return description;
  }

  public async updateDocumentStatus(
    actionType: string,
    invIDs: number[],
    holdReason?: string | null
  ) {
    // actionType can be "delete", "hold", "cancel", or "resume"
    const allowedActions = ["delete", "hold", "cancel", "resume"];
    if (!allowedActions.includes(actionType)) {
      throw new Error("Requested document action is not supported");
    }
    let invIDsString = "?";
    invIDs.map(invId => {
      invIDsString += `InvIDs=${invId}&`;
    });
    if (holdReason) {
      const response: UpdatedInvoiceResponse[] = (
        await app.$ravenapi.post(
          `api/invoice/${actionType}/${invIDsString}Reason=${holdReason}`,
          {}
        )
      ).data;
      return this.processDocumentStatusUpdateResponse(response);
    } else {
      const response: UpdatedInvoiceResponse[] = (
        await app.$ravenapi.post(
          `api/invoice/${actionType}/${invIDsString}`,
          {}
        )
      ).data;
      return this.processDocumentStatusUpdateResponse(response);
    }
  }

  public async getPreviousNextAsync(requestBody: DocumentOrderRequest) {
    const response = await app.$ravenapi.patch(
      "api/invoice/previousnext",
      requestBody
    );
    return response.data;
  }

  public async addInvoiceToWorkdayAsync(invID: number) {
    const response: any = await app.$ravenapi.post(
      `api/invoice/addtoworkday?InvID=${invID}`,
      {}
    );
    return response;
  }

  public async releaseValidationServicesInvoice(invID: number) {
    const response = await app.$ravenapi.post(
      `api/invoice/release?InvID=${invID}`,
      {}
    );
    return response;
  }

  public async unclaimInvoice(invID: number) {
    const response = await app.$ravenapi.post(
      `api/invoice/unclaim/${invID}`,
      {}
    );
    return response;
  }
}

export const invoiceService = new InvoiceService(); // singleton
