import { app } from "@/main";

class DocumentListService {
  public async getInvoiceList(
    invoicesGroup: string,
    invoicesStatus: string,
    startDate: string,
    stopDate: string
  ) {
    if (invoicesGroup.trim() === "") {
      throw new Error(
        "DocumentListService.getInvoiceList(). [invoicesGroup] cannot be empty."
      );
    }

    if (invoicesStatus.trim() === "") {
      throw new Error(
        "DocumentListService.getInvoiceList(). [invoicesStatus] cannot be empty."
      );
    }
    let requestUrl = `api/invoices/${invoicesGroup}/${invoicesStatus}`;
    if (invoicesGroup === "completed") {
      if (startDate && stopDate) {
        requestUrl += `?startDate=${startDate}&stopDate=${stopDate}`;
      } else if (startDate) {
        requestUrl += `?startDate=${startDate}`;
      } else if (stopDate) {
        requestUrl += `?stopDate=${stopDate}`;
      }
    }
    return await app.$ravenapi.get(requestUrl);
  }
}

export const documentListService = new DocumentListService(); // singleton
