















import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class IconTooltip extends Vue {
  @Prop() text!: string;
  @Prop() icon!: string;
  @Prop() customClass!: string;
  @Prop() showContent!: boolean;
  private shouldShowContent = this.showContent || false;
}
