



















































import { Vue, Component, Prop } from "vue-property-decorator";
import StatusTag from "./StatusTag.vue";
import { documentStatusTags } from "@/shared/constants/status-tags";

@Component({
  components: {
    "status-tag": StatusTag
  }
})
export default class StatusTooltip extends Vue {
  @Prop() states!: any[];
  private supportedStatesCodes = documentStatusTags.map(el => el.code);
  private filteredStates = this.states.filter(el =>
    this.supportedStatesCodes.includes(el)
  );
}
